import { getPermalink } from './utils/permalinks'

export interface Link {
  ariaLabel?: string
  href: string
  icon?: string
  rel?: string
  target?: string
  text: string
}

export interface Links {
  links: Link[]
  title?: string
}

export const headerData = {
  actions: [],
  links: [
    {
      href: getPermalink('/'),
      text: 'Home',
    },
    {
      href: getPermalink('/services'),
      text: 'Services',
    },
    {
      href: getPermalink('/about'),
      text: 'About',
    },
    {
      href: getPermalink('/contact'),
      text: 'Contact',
    },
    // {
    //   href: getPermalink('/blog'),
    //   text: 'Blog',
    // },
  ],
}

export const footerData: {
  links: Record<string, Links>
} = {
  links: {
    column1: {
      links: [
        { href: getPermalink('/'), text: 'Home' },
        { href: getPermalink('/about'), text: 'About' },
        { href: getPermalink('/services'), text: 'Services' },
        { href: getPermalink('/contact'), text: 'Contact' },
      ],
      title: 'Company',
    },
    column2: {
      links: [
        {
          ariaLabel: 'LinkedIn',
          href: 'https://www.linkedin.com/showcase/kodehort/',
          rel: 'noopener noreferrer',
          target: '_blank',
          text: 'LinkedIn',
        },
        {
          ariaLabel: 'Twitter',
          href: 'https://twitter.com/kodehort',
          rel: 'noopener noreferrer',
          target: '_blank',
          text: 'Twitter',
        },
        {
          ariaLabel: 'Github',
          href: 'https://github.com/kodehort',
          rel: 'noopener noreferrer',
          target: '_blank',
          text: 'Github',
        },
      ],
      title: 'Follow us',
    },
    column3: {
      links: [
        { href: getPermalink('/cookie-policy'), text: 'Cookie policy' },
        { href: getPermalink('/privacy-policy'), text: 'Privacy policy' },
        { href: getPermalink('/data-policy'), text: 'Data protection policy' },
      ],
      title: 'Policies',
    },
  },
}
