import defaultImage from '~/assets/icon-512.png'

const CONFIG = {
  basePathname: '/',

  blog: {
    category: {
      disabled: false,
      noindex: true,
      pathname: 'category', // Category main path /category/some-category
    },
    disabled: true,

    list: {
      disabled: false,
      noindex: false,
      pathname: 'blog', // Blog main path, you can change this to "articles" (/articles)
    },

    post: {
      disabled: false,
      noindex: false,
      permalink: '/%slug%', // Variables: %slug%, %year%, %month%, %day%, %hour%, %minute%, %second%, %category%
    },

    postsPerPage: 4,

    tag: {
      disabled: false,
      noindex: true,
      pathname: 'tag', // Tag main path /tag/some-tag
    },
  },
  dateFormatter: new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'short',
    timeZone: 'UTC',
    year: 'numeric',
  }),
  defaultImage: defaultImage,

  defaultTheme: 'system', // Values: "system" | "light" | "dark" | "light:only" | "dark:only"
  description:
    'Access expert technical consulting and leadership services to optimize your team and accelerate your growth. Our services include fractional or interim CTOs, embedded technical and product team coaching, early-stage product development and team design, and design sprints and rapid prototyping. Customized solutions tailored to your specific needs.',

  keywords:
    'Technical Consulting, Leadership Services, Fractional CTO, Interim CTO, Agile Coaching, Product Development, Team Design, Design Sprint, Rapid Prototyping, Customized Solutions',

  language: 'en',
  name: 'Kodehort',

  origin: 'https://kodehort.com',

  textDirection: 'ltr',
  title: 'Fractional or interim CTO services',
  trailingSlash: false,
}

export const SITE = { ...CONFIG, blog: undefined }
export const BLOG = CONFIG.blog
export const DATE_FORMATTER = CONFIG.dateFormatter
