import type { ElementOf, PolymorphicProps } from '@kobalte/core/polymorphic'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '~/components/ui/collapsible'
import { type Link as LinkType } from '~/data'
import { cn } from '~/libs/cn'
import { getHomePermalink } from '~/utils/permalinks'
import { FaSolidBars } from 'solid-icons/fa'
import {
  For,
  Show,
  splitProps,
  type Component,
  type ValidComponent,
} from 'solid-js'
import type * as Solid from 'solid-js'
import { Motion, Presence } from 'solid-motionone'

import { Logo } from './logo'

interface LinkCommonProps<T extends HTMLElement = HTMLElement> {
  id: string
  ref: ((el: T) => void) | T
}
type LinkContentProps<T extends HTMLElement | ValidComponent = HTMLElement> =
  Partial<LinkCommonProps<ElementOf<T>>>

type LinkProps<T extends ValidComponent = 'a'> = {
  children: Solid.JSX.Element
  class?: string
} & LinkContentProps<T>

const Link = <T extends ValidComponent = 'a'>(
  props: PolymorphicProps<T, LinkProps<T>>,
) => {
  const [local, rest] = splitProps(props, ['children', 'class'])
  return (
    <a
      class={cn(
        'text-base font-medium text-gray-950 underline-offset-4 hover:underline',
        local.class,
      )}
      {...rest}
    >
      {local.children}
    </a>
  )
}

interface NavbarProps {
  links: LinkType[]
  pathname: string
}

function isActive(href: string, pathname: string) {
  if (href === '/') {
    return pathname === '/'
  }
  return pathname.startsWith(href)
}

const DesktopNav: Component<NavbarProps> = (props) => {
  return (
    <Show when={props.links.length > 0}>
      <nav class="relative hidden lg:flex">
        <For each={props.links}>
          {({ ariaLabel, href, text }) => (
            <Link
              class={cn(
                'flex items-center px-4 py-3 bg-blend-multiply data-[hover]:bg-black/[2.5%]',
                isActive(href, props.pathname)
                  ? 'font-bold text-brand-orange'
                  : '',
              )}
              href={href}
              title={ariaLabel}
            >
              {text}
            </Link>
          )}
        </For>
      </nav>
    </Show>
  )
}

const MobileNavButton: Component<NavbarProps> = (props) => {
  return (
    <Show when={props.links.length > 1}>
      <CollapsibleTrigger
        aria-label="Open main menu"
        class="flex size-12 items-center justify-center self-center rounded-lg hover:bg-black/5 lg:hidden"
      >
        <FaSolidBars class="size-6" />
      </CollapsibleTrigger>
    </Show>
  )
}

const MobileNav: Component<NavbarProps> = (props) => {
  return (
    <Show when={props.links.length > 1}>
      <CollapsibleContent class="lg:hidden">
        <div class="flex flex-col gap-6 py-4">
          <For each={props.links}>
            {({ ariaLabel, href, text }, index) => (
              <Motion
                animate={{ opacity: 1, rotateX: 0 }}
                exit={{ opacity: 0, rotateX: -90 }}
                id={href}
                initial={{ opacity: 0, rotateX: -90 }}
                transition={{
                  duration: 0.1,
                  easing: 'ease-in-out',
                  rotateX: { delay: index() * 0.1, duration: 0.2 },
                }}
              >
                <Link
                  class={cn(
                    isActive(href, props.pathname)
                      ? 'font-bold text-brand-orange'
                      : '',
                  )}
                  href={href}
                  title={ariaLabel}
                >
                  {text}
                </Link>
              </Motion>
            )}
          </For>
        </div>
      </CollapsibleContent>
    </Show>
  )
}

export const Navbar: Component<NavbarProps> = (props) => {
  return (
    <Presence exitBeforeEnter>
      <Collapsible
        as="header"
        class="py-3 sm:py-6"
      >
        <div class="relative flex justify-between">
          <div class="flex lg:flex-1">
            <a
              class="flex items-center"
              href={getHomePermalink()}
            >
              <span class="sr-only">Kodehort Limited</span>
              <Logo class="h-8 w-auto sm:h-12" />
            </a>
          </div>
          <DesktopNav {...props} />
          <MobileNavButton {...props} />
        </div>
        <MobileNav {...props} />
      </Collapsible>
    </Presence>
  )
}
